import { InteractiveBrowserCredential } from "@azure/identity";
import { BlobServiceClient } from "@azure/storage-blob";
import { useEffect, useState } from "react";
import React from "react";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";
import { getContainerName } from "../../utils/getContainerName";
import { isImage } from "../../utils/isImage";
interface DocumentViewerProps {
  blobName: string;
}

interface DownloadedBlobRecord {
  blobName: string;
  downloadURL: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ blobName }) => {
  const [documentUrl, setDocumentUrl] = useState<string>("");
  const [downloadedBlobs, setDownloadedBlobs] = useState<
    DownloadedBlobRecord[]
  >([]);
  const [pageNumber, setPageNumber] = useState<string>();
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  useEffect(() => {
    const getDocumentToShow = async (
      storageAccountName: string,
      containerName: string,
      blobPath: string,
      blobName: string
    ) => {
      const existingDownloadedBlobsRecord = downloadedBlobs.filter(
        (elem) => elem.blobName === blobName
      );

      if (blobName.includes("page=")) {
        setPageNumber(blobName.split("page=")[1]);
      }

      if (existingDownloadedBlobsRecord.length < 1) {
        const signInOptions = {
          clientId: process.env.REACT_APP_AZURE_AAD_APP_CLIENT_ID,
          tenantId: process.env.REACT_APP_AZURE_AAD_APP_TENANT_ID,
          loginStyle: 'redirect',
          prompt: "select_account",
        };

        const blobServiceClient = new BlobServiceClient(
          "https://" + storageAccountName + ".blob.core.windows.net/",
          new InteractiveBrowserCredential(signInOptions)
        );

        // const blobServiceClient = new BlobServiceClient(
        //   "https://" + storageAccountName + ".blob.core.windows.net/"
        // );

        const containerClient =
          blobServiceClient.getContainerClient(containerName);

        const blobClient = containerClient.getBlobClient(
          decodeURI(blobPath + blobName.split("?")[0])
        );

        let downloaded = await blobClient.download();

        const blob = (await downloaded.blobBody) as Blob;

        const downloadURL = URL.createObjectURL(blob);

        if (downloadURL !== documentUrl) {
          setDocumentUrl(downloadURL);
        }

        const newDownloadedBlobs = [...downloadedBlobs];
        newDownloadedBlobs.push({ blobName, downloadURL });
        setDownloadedBlobs(newDownloadedBlobs);
      } else {
        setDocumentUrl(existingDownloadedBlobsRecord[0].downloadURL);
      }
    };

    if (blobName && blobName.length > 0) {
      getDocumentToShow(
        "stailegaldev10715440",
        getContainerName(blobName, chatContext),
        isImage(blobName) ? "Images/" : "",
        blobName
      );
    }
  }, [blobName]);

  return isImage(blobName) ? (
    <img src={documentUrl} className="w-full h-full" />
  ) : (
    <embed
      src={`${documentUrl}#page=${pageNumber}`}
      type="application/pdf"
      className="w-full h-full"
    />
  );
};

export default DocumentViewer;
