import { IndexDefinition } from "../models/indexdefinition";

export const indexes_business_line: IndexDefinition[] = [
  {
    indexName: "business_all",
    displayText: "All Business Lines",
  },
  {
    indexName: "business_applied-solutions",
    displayText: "Applied Solutions",
  },
  {
    indexName: "business_design-build-and-alternative-delivery",
    displayText: "Design Build and Alternative Delivery",
  },
  {
    indexName: "business_property-and-buildings",
    displayText: "Property and Buildings",
  },
  {
    indexName: "business_earth-and-environment",
    displayText: "Earth and Environment",
  },
  {
    indexName: "business_advisory-services",
    displayText: "Advisory Services",
  },
];

