import { ChatContext } from "../contexts/ChatContext/ChatContextProvider";
import { ChatContextType } from "../contexts/ChatContext/ChatContextType";
import { DomainTypes } from "../enums/DomainTypes";
import { isImage } from "./isImage";

  export const getContainerName = (blobName: string, chatContext: ChatContextType | null) => {
    if (isImage(blobName)) {
      return "doc-extracts";
    } else {
      // for citation download page
      if (window.location.href.includes("/citation")) {
        // on citation download page context.currentDomain will be reset to default and lost, it is not reliable anymore
        // instead we check the url to get the true currentDomain
        return window.location.href.includes(
          "/" + DomainTypes.policy.toLowerCase()
        )
          ? process.env.POLICY_BLOB_CONTAINER ?? "documents"
          : process.env.CONTRACT_BLOB_CONTAINER ?? "contract-documents";
      } else {
        // for chat page
        // on chat page we check chatContext?.currentDomain
        return chatContext?.currentDomain === DomainTypes.policy
          ? process.env.POLICY_BLOB_CONTAINER ?? "documents"
          : process.env.CONTRACT_BLOB_CONTAINER ?? "contract-documents";
      }
    }
  };