import { useEffect, useState } from "react";
import { IndexDefinition } from "../../models/indexdefinition";
import React from "react";
import { SCFilterPillDropdownHeight } from "../../enums/SCFilterPillDropdownHeight";

interface FilterPillProps {
  pillname: string;
  data: IndexDefinition[];
  addCallback: (oldValue: string, newValue: string) => void;
  removeCallback: (value: string) => void;
  openFilters: string[];
  setOpenFilters: (openFilters: string[]) => void;
  searchEnabled?: boolean;
  allSelected?: boolean;
  maxHeight?: string;
  defaultAppliedLocalFilter?: string;
}
/** Single-Choice (SC) Filter Pill*/
const SCFilterPill: React.FC<FilterPillProps> = ({
  pillname,
  data,
  addCallback,
  removeCallback,
  openFilters,
  setOpenFilters,
  searchEnabled,
  allSelected,
  maxHeight = SCFilterPillDropdownHeight.Default,
  defaultAppliedLocalFilter,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [appliedLocalFilter, setAppliedLocalFilter] = useState<string>(
    defaultAppliedLocalFilter ?? ""
  );
  const [selectedItem, setSelectedItem] = useState<string | null>(
    data.find((item) => item.displayText === pillname)?.indexName ?? null
  );
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Function to handle item selection
  const handleSelection = (item: string) => {
    if (selectedItem === item) {
      // If the item is already selected, deselect it
      setSelectedItem(defaultAppliedLocalFilter ?? null);
      removeFromAppliedLocalFilters(item);
    } else {
      // Otherwise, select the new item and close the filter and update selection
      setSelectedItem(item);
      addToAppliedLocalFilters(item);
    }
  };

  useEffect(() => {
    if (allSelected) {
      setSelectedItem(defaultAppliedLocalFilter ?? null);
      setAppliedLocalFilter("");
    }
  }, [allSelected]);

  useEffect(() => {
    const index = openFilters.indexOf(pillname, 0);
    if (index > -1) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [openFilters, pillname]);

  useEffect(() => {
    const indexItemForPillName = data.find(
      (item) => item.displayText === pillname
    );

    if (pillname && indexItemForPillName) {
      setAppliedLocalFilter(indexItemForPillName.indexName);
    }
  }, [pillname]);

  // Function to add value from Applied Local Filters
  const addToAppliedLocalFilters = (value: string) => {
    const oldValue = appliedLocalFilter;
    setAppliedLocalFilter(value);
    addCallback(oldValue, value);
  };

  // Function to remove value from Applied Local Filters
  const removeFromAppliedLocalFilters = (value: string) => {
    setAppliedLocalFilter("");
    removeCallback(value);
  };

  // Function to close item selection
  const changeVisibility = () => {
    setVisible(!visible);
    if (!visible) {
      setOpenFilters([pillname]);
    }
  };

  return (
    <div
      className={
        appliedLocalFilter
          ? "border border-blue rounded-full my-auto bg-blue"
          : "border border-blue rounded-full my-auto"
      }
    >
      <div className={appliedLocalFilter ? "p-2 text-white" : "p-2"}>
        <button type="button" onClick={() => changeVisibility()}>
          {appliedLocalFilter
            ? data.find((item) => item.indexName === appliedLocalFilter)
                ?.displayText
            : defaultAppliedLocalFilter ?? pillname}
          <div className="inline-block pl-1 pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current stroke-0"
              width="8"
              height="8"
              fill="currentColor"
              viewBox="0 0 8 4"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M0.666016 0.333252L3.99935 3.66659L7.33268 0.333252H0.666016Z"
              />
            </svg>
          </div>
        </button>
      </div>
      <div
        className={
          visible
            ? "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 w-40 options-scroll"
            : "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 w-40 options-scroll invisible"
        }
      >
        <div
          style={{
            maxHeight: maxHeight, // Set max height to enable scrolling, set to 100px to make sure all the selection can be selected
            overflowY: "auto", // Enable vertical scrolling
            //scrollbarWidth: "none",
          }}
        >
          {searchEnabled && (
            <div>
              <div className="border border-light-gray w-full flex flex-row">
                <div className="flex-none m-auto p-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7559 11.2549H11.9659L11.6859 10.9849C12.6659 9.84488 13.2559 8.36488 13.2559 6.75488C13.2559 3.16488 10.3459 0.254883 6.75586 0.254883C3.16586 0.254883 0.255859 3.16488 0.255859 6.75488C0.255859 10.3449 3.16586 13.2549 6.75586 13.2549C8.36586 13.2549 9.84586 12.6649 10.9859 11.6849L11.2559 11.9649V12.7549L16.2559 17.7449L17.7459 16.2549L12.7559 11.2549ZM6.75586 11.2549C4.26586 11.2549 2.25586 9.24488 2.25586 6.75488C2.25586 4.26488 4.26586 2.25488 6.75586 2.25488C9.24586 2.25488 11.2559 4.26488 11.2559 6.75488C11.2559 9.24488 9.24586 11.2549 6.75586 11.2549Z"
                      fill="#1E252B"
                    />
                  </svg>
                </div>
                <div className="flex-1 m-auto">
                  <input
                    type="text"
                    className="w-full h-full p-2"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          )}

          {/* Options */}
          {data
            .filter((word) =>
              word.displayText
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase())
            )
            .map((item, index) => (
              <div className="flex-row gap-1" key={item.indexName}>
                <React.Fragment key={index}>
                  <button
                    key={item.indexName}
                    onClick={() => {
                      handleSelection(item.indexName.trim());
                      changeVisibility();
                    }}
                    onMouseEnter={() => setHoveredItem(item.indexName)}
                    onMouseLeave={() => setHoveredItem(null)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      backgroundColor:
                        selectedItem === item.indexName ||
                        hoveredItem === item.indexName
                          ? "#d8e5f0"
                          : "white",
                      color:
                        selectedItem === item.indexName ||
                        hoveredItem === item.indexName
                          ? "white"
                          : "black",
                      border: "none",
                      borderRadius: "0px",
                      cursor: "hand",
                      transition: "background-color 0.3s ease",
                      textAlign: "left",
                    }}
                  >
                    {item.displayText}
                  </button>
                  {index < data.length - 1 && (
                    <div
                      style={{
                        height: "0.5px",
                        backgroundColor: "#d8e5f0",
                        width: "100%",
                        alignSelf: "center",
                      }}
                    />
                  )}
                </React.Fragment>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SCFilterPill;
