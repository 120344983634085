import React, { useEffect, useState } from "react";
import { SCFilterPill, SnippetButton, WSPRule } from "../../../components";
import { SnippetType } from "../../../enums/SnippetType";
import { ChatContextType } from "../../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../../contexts/ChatContext/ChatContextProvider";
import { useNavigate } from "react-router-dom";
import { base, promptGroup1, promptGroup2, promptGroup3 } from "../../../data";
import { FilterScope } from "../../../enums/FilterScope";
import { DomainTypes } from "../../../enums/DomainTypes";
import { indexes_contract_clause } from "../../../data/indexes_contract_clause";
import { indexes_business_line } from "../../../data/indexes_business_line";
import {
  ContractCannedQuestions,
  ContractGuidelineQuestions,
} from "../../../data/promptsnippets";
import { IndexDefinition } from "../../../models/indexdefinition";
import { localCompare } from "../../../utils/localCompare";
import { DefaultAppliedLocalFilter } from "../../../enums/DefaultAppliedLocalFilter";

const ContractHome = () => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [inputValue, setInputValue] = useState<string>(
    chatContext ? chatContext!.chat.question : ""
  );
  const [submitReady, setSubmitReady] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(
    chatContext ? chatContext!.allSelected : true
  );
  const [appliedFilters, setAppliedFilters] = useState<string[]>(
    chatContext ? chatContext!.indexes : []
  );

  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const [filterScope, setFilterScope] = useState<FilterScope>();
  const navigate = useNavigate();
  const [openContractClauseFilters, setOpenContractClauseFilters] = useState<
    string[]
  >([]);
  const [openBusinessLineFilters, setOpenBusinessLineFilters] = useState<
    string[]
  >([]);
  useEffect(() => {
    chatContext?.setCurrentDomain(DomainTypes.contract);
  }, []); // Empty array ensures it runs only once

  // hook to check if it is ready to submit
  useEffect(() => {
    if (inputValue.trim().length > 0 && appliedFilters.length > 1) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  }, [inputValue, appliedFilters]);

  const handleUserChange = (value: string) => {
    setInputValue(value);
  };

  const handleAddToPrompt = (value: string) => {
    setInputValue(value);
  };

  const handleSubmit = () => {
    chatContext?.saveChatAndIndexes(
      { question: inputValue.trim(), history: "" },
      appliedFilters,
      filterScope
    );
    navigate("/contract/chat");
  };

  const addToAppliedFilters = (value: string, startFresh: boolean) => {
    let newFilters = [...appliedFilters];

    const isBusinessLine =
      indexes_business_line.filter((item) => item.indexName === value).length >
      0;

    const isContractClause =
      indexes_contract_clause.filter((item) => item.indexName === value)
        .length > 0;

    // if new value is a contract clause, then remove all of the old contract clauses
    if (isContractClause) {
      newFilters = newFilters.filter(
        (item) =>
          indexes_contract_clause.filter((i) => i.indexName === item).length < 1
      );
    }

    // if new values is a business line, then remove all of the old business lines
    if (isBusinessLine) {
      newFilters = newFilters.filter(
        (item) =>
          indexes_business_line.filter((i) => i.indexName === item).length < 1
      );
    }

    newFilters.push(value);

    setAppliedFilters(newFilters);
    chatContext?.saveIndexes(newFilters);
  };

  const setAllResources = () => {
    setAppliedFilters([
      indexes_business_line[0].indexName,
      indexes_contract_clause[0].indexName,
    ]);
    chatContext?.saveIndexes([
      indexes_business_line[0].indexName,
      indexes_contract_clause[0].indexName,
    ]);
  };

  const updateFilterValues = (oldValue: string, newValue: string) => {
    const newFilters = [...appliedFilters];

    const oldValueIndex = newFilters.indexOf(oldValue);

    if (oldValueIndex > -1) {
      newFilters.splice(newFilters.indexOf(oldValue), 1, newValue);
    } else {
      newFilters.push(newValue);
    }

    setAppliedFilters(newFilters);
  };

  const removeFromAppliedFilters = (value: string) => {
    const newFilters = [...appliedFilters];

    const newValues = value.split(",");
    newValues.forEach((newValue) => {
      do {
        const index = newFilters.indexOf(newValue, 0);
        if (index > -1) {
          newFilters.splice(index, 1);
        }
      } while (newFilters.indexOf(newValue, 0) > -1);
    });

    setAppliedFilters(newFilters);
  };

  const closeAllFilters = () => {
    setOpenFilters([]);
  };

  return (
    <div className="flex flex-col">
      <div className="bg-light-blue bg-[url('/public/bg-contract.png')] bg-cover bg-center bg-no-repeat">
        <div className="bg-light-gray-transparent py-8 px-8 mx-5 my-5">
          <div className="pb-6">
            <h2 className="text-green">
              Ask me anything about{" "}
              <b>WSP USA's Contract Review Requirements.</b>
            </h2>
          </div>
          <div>
            <div className="bg-white border border-light-gray-40 p-2">
              <div>
                <textarea
                  className="w-full resize-none text-charcoal-dark"
                  rows={10}
                  placeholder="I want to know more about..."
                  value={inputValue}
                  onChange={(e) => handleUserChange(e.target.value)}
                  onFocus={() => closeAllFilters()}
                ></textarea>
              </div>
              <div className="flex flex-row text-charcoal-dar">
                <div className="flex flex-1 text-xs m-auto gap-1 flex-wrap">
                  <div className="my-auto">Filters:</div>

                  {/* Contract Clause */}
                  <SCFilterPill
                    pillname={
                      indexes_contract_clause.find(
                        (item) =>
                          item.indexName ===
                          chatContext?.indexes.find(
                            (index) => item.indexName === index
                          )
                      )?.displayText ?? indexes_contract_clause[0].displayText
                    }
                    data={indexes_contract_clause
                      .filter(
                        (item) =>
                          item.indexName ===
                          indexes_contract_clause[0].indexName
                      )
                      .concat(
                        indexes_contract_clause
                          .filter(
                            (item) =>
                              item.indexName !==
                              indexes_contract_clause[0].indexName
                          )
                          .sort((a, b) =>
                            localCompare(a.displayText, b.displayText)
                          )
                      )}
                    // addCallback={addToAppliedFilters}
                    addCallback={updateFilterValues}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openContractClauseFilters}
                    setOpenFilters={setOpenContractClauseFilters}
                    searchEnabled={true}
                    defaultAppliedLocalFilter={
                      DefaultAppliedLocalFilter.ContractClause
                    }
                  />

                  {/* Business Line */}
                  <SCFilterPill
                    pillname={
                      indexes_business_line.filter(
                        (item) =>
                          item.indexName ===
                          chatContext?.indexes.find(
                            (index) => index == item.indexName
                          )
                      ).length > 0
                        ? indexes_business_line.filter(
                            (item) =>
                              item.indexName ===
                              chatContext?.indexes.find(
                                (index) => index == item.indexName
                              )
                          )[0].displayText
                        : indexes_business_line[0].displayText
                    }
                    data={indexes_business_line
                      .filter(
                        (item) =>
                          item.indexName === indexes_business_line[0].indexName
                      )
                      .concat(
                        indexes_business_line
                          .filter(
                            (item) =>
                              item.indexName !==
                              indexes_business_line[0].indexName
                          )
                          .sort((a, b) =>
                            localCompare(a.displayText, b.displayText)
                          )
                      )}
                    // addCallback={addToAppliedFilters}
                    addCallback={updateFilterValues}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openBusinessLineFilters}
                    setOpenFilters={setOpenBusinessLineFilters}
                    searchEnabled={true}
                    defaultAppliedLocalFilter={
                      DefaultAppliedLocalFilter.BusinessLine
                    }
                  />
                </div>
                <div className="mt-auto">
                  <button
                    type="button"
                    className={
                      submitReady
                        ? "bg-blue text-white w-full p-2"
                        : "bg-disabled text-white w-full p-2"
                    }
                    disabled={!submitReady}
                    onClick={handleSubmit}
                  >
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <div className="grid grid-cols-1 gap-4 mx-5">
          <div className="font-semibold	pt-8">
            Explore the Contract Review Companion with the following suggested
            questions...
            <WSPRule />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {ContractCannedQuestions.map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.New}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
          <div className="font-semibold	">
            Contract Review Guidelines
            <WSPRule />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {ContractGuidelineQuestions.sort((a, b) =>
              a.text.localeCompare(b.text)
            ).map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.New}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
          <div className="pb-2"></div>
        </div>
      </div>
    </div>
  );
};

export default ContractHome;
