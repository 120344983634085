import React, { useEffect, useState } from "react";
import { PromptSnippet } from "../../../models/promptsnippet";
import { SnippetButton, WSPRule } from "../../../components";
import { SnippetType } from "../../../enums/SnippetType";
import { ChatContextType } from "../../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../../contexts/ChatContext/ChatContextProvider";
import { useNavigate } from "react-router-dom";
import { SCFilterPill } from "../../../components";
import {
  base,
  promptGroupPolicy,
  promptSnippets,
  indexes_global,
  indexes_specify_country,
} from "../../../data";
import { FilterScope } from "../../../enums/FilterScope";
import { DomainTypes } from "../../../enums/DomainTypes";
import { indexes_business_line } from "../../../data/indexes_business_line";
import { indexes_contract_clause } from "../../../data/indexes_contract_clause";
import { localCompare } from "../../../utils/localCompare";

const PolicyHome = () => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [inputValue, setInputValue] = useState<string>(
    chatContext ? chatContext!.chat.question : ""
  );
  const [submitReady, setSubmitReady] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(
    chatContext ? chatContext!.allSelected : true
  );
  const [appliedFilters, setAppliedFilters] = useState<string[]>(
    chatContext
      ? [
          ...chatContext!.indexes.filter(
            (index) =>
              !indexes_business_line.find((item) => item.indexName === index) &&
              !indexes_contract_clause.find((item) => item.indexName === index)
          ),
        ]
      : [indexes_global.indexName]
  );

  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const [filterScope, setFilterScope] = useState<FilterScope>();
  const navigate = useNavigate();

  useEffect(() => {
    chatContext?.setCurrentDomain(DomainTypes.policy);
  }, []); // Empty array ensures it runs only once

  useEffect(() => {
    if (inputValue.trim().length > 0) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  }, [inputValue]);

  const handleUserChange = (value: string) => {
    setInputValue(value);
  };

  const handleAddToPrompt = (value: string) => {
    setInputValue(value);
  };

  const handleSubmit = () => {
    chatContext?.saveChatAndIndexes(
      { question: inputValue.trim(), history: "" },
      appliedFilters,
      filterScope
    );
    navigate("/policy/chat");
  };

  const addToAppliedFilters = (value: string, startFresh: boolean) => {
    let newFilters = [...appliedFilters];

    if (startFresh) {
      newFilters = [indexes_global.indexName];
    }

    const newValues = value.split(",");

    newValues.forEach((newValue) => {
      const index = newFilters.indexOf(newValue, 0);
      if (index < 0 && newValue !== "None") {
        newFilters.push(newValue);
      }
    });

    setAppliedFilters(newFilters);
    chatContext?.saveIndexes(newFilters);
  };

  const updateFilterValues = (oldValue: string, newValue: string) => {
    const newFilters = [...appliedFilters];

    const oldValueIndex = newFilters.indexOf(oldValue);

    if (oldValueIndex > 0) {
      newFilters.splice(newFilters.indexOf(oldValue), 1, newValue);
    } else {
      const newValueIndex = newFilters.indexOf(newValue);
      if (newValueIndex < 0) newFilters.push(newValue);
    }

    setAppliedFilters(newFilters);
    chatContext?.saveIndexes(newFilters);
  };

  const removeFromAppliedFilters = (value: string) => {
    const newFilters = [...appliedFilters];

    const newValues = value.split(",");
    newValues.forEach((newValue) => {
      do {
        const index = newFilters.indexOf(newValue, 0);
        if (index > -1) {
          newFilters.splice(index, 1);
        }
      } while (newFilters.indexOf(newValue, 0) > -1);
    });

    if (newFilters.length < 1) {
      setAppliedFilters(base.map((item) => item.indexName));
      chatContext?.saveAllSelected(true);
      setAllSelected(true);
    } else {
      setAppliedFilters(newFilters);
    }

    chatContext?.saveIndexes(newFilters);
  };

  const closeAllFilters = () => {
    setOpenFilters([]);
  };

  return (
    <div className="flex flex-col">
      <div className="bg-light-blue py-10 bg-[url('/public/bg-policy.png')] bg-cover bg-center bg-no-repeat">
        <div className="bg-light-gray-transparent py-8 px-8 mx-5 my-5">
          <div className="pb-6">
            <h2 className="text-red">
              <b>ask me anything</b> about wsp policies.
            </h2>
          </div>
          <div>
            <div className="bg-white border border-light-gray-40 p-2">
              <div>
                <textarea
                  className="w-full resize-none text-charcoal-dark"
                  rows={10}
                  placeholder="I want to learn/analyze..."
                  value={inputValue}
                  onChange={(e) => handleUserChange(e.target.value)}
                  onFocus={() => closeAllFilters()}
                ></textarea>
              </div>
              <div className="flex flex-row text-charcoal-dar">
                <div className="flex flex-1 text-xs m-auto gap-1 flex-wrap">
                  <div className="my-auto">Filters:</div>
                  {/* Global */}
                  <div className="my-auto mx-1">
                    <b>{indexes_global.displayText}</b>
                  </div>
                  {/* Divider */}
                  <div className="border-r border-light-gray pl-1 mr-1 my-1"></div>
                  {/* Specify Country */}
                  <SCFilterPill
                    pillname="Specify Country"
                    data={indexes_specify_country.sort((a, b) =>
                      localCompare(a.displayText, b.displayText)
                    )}
                    // addCallback={addToAppliedFilters}
                    addCallback={updateFilterValues}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                  />
                </div>
                <div className="mt-auto">
                  <button
                    type="button"
                    className={
                      submitReady
                        ? "bg-blue text-white w-full p-2"
                        : "bg-disabled text-white w-full p-2"
                    }
                    disabled={!submitReady}
                    onClick={handleSubmit}
                  >
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <div className="grid grid-cols-1 gap-4 mx-5">
          <div className="font-semibold	pt-8">
            Get started with these prompt snippets.
            <WSPRule />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {promptSnippets.map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.New}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
          <div className="font-semibold	">
            Explore the Policy Tool with the following suggested questions...
            <WSPRule />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {promptGroupPolicy.map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.New}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
          <div className="pb-2"></div>
        </div>
      </div>
    </div>
  );
};

export default PolicyHome;
