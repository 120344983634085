import { IndexDefinition } from "../models/indexdefinition";

export const indexes_specify_country: IndexDefinition[]  = [
  {
    displayText: "None",
    indexName: "None",
    regionIndexName: "None",
  },
  {
    displayText: "Africa",
    indexName: "Africa",
    regionIndexName: "Africa",
  },
  {
    displayText: "Canada",
    indexName: "Canada",
    regionIndexName: "Canada",
  },
  {
    displayText: "Finland",
    indexName: "Finland",
    regionIndexName: "Nordics",
  },
  {
    displayText: "Norway",
    indexName: "Norway",
    regionIndexName: "Nordics",
  },
  {
    displayText: "Sweden",
    indexName: "Sweden",
    regionIndexName: "Nordics",
  },
  {
    displayText: "Denmark",
    indexName: "Denmark",
    regionIndexName: "Nordics",
  },
  {
    displayText: "India",
    indexName: "India",
    regionIndexName: "India",
  },
  {
    displayText: "Middle East",
    indexName: "Middle East",
    regionIndexName: "Middle East",
  },
  {
    displayText: "US",
    indexName: "US",
    regionIndexName: "US",
  },
  {
    displayText: "Australia",
    indexName: "Australia",
    regionIndexName: "Australia",
  },
  {
    displayText: "New Zealand",
    indexName: "New Zealand",
    regionIndexName: "New Zealand",
  },
  {
    displayText: "Colombia",
    indexName: "Colombia",
    regionIndexName: "LAC",
  },
  {
    displayText: "Mexico",
    indexName: "Mexico",
    regionIndexName: "LAC",
  },
  {
    displayText: "Peru",
    indexName: "Peru",
    regionIndexName: "LAC",
  },
  {
    displayText: "Chile",
    indexName: "Chile",
    regionIndexName: "LAC",
  },
  {
    displayText: "Brazil",
    indexName: "Brazil",
    regionIndexName: "LAC",
  },
  {
    displayText: "Argentina",
    indexName: "Argentina",
    regionIndexName: "LAC",
  },
  {
    displayText: "Panama",
    indexName: "Panama",
    regionIndexName: "LAC",
  },
  {
    displayText: "Caribbean",
    indexName: "Caribbean",
    regionIndexName: "LAC",
  },
  {
    displayText: "UK",
    indexName: "UK",
    regionIndexName: "UK",
  },
    {
    displayText: "Ireland",
    indexName: "Ireland",
    regionIndexName: "UK",
  },
  {
    displayText: "France",
    indexName: "France",
    regionIndexName: "Central Europe",
  },
  {
    displayText: "Germany",
    indexName: "Germany",
    regionIndexName: "Central Europe",
  },
  {
    displayText: "Netherlands",
    indexName: "Netherlands",
    regionIndexName: "Central Europe",
  },
  {
    displayText: "Poland",
    indexName: "Poland",
    regionIndexName: "Central Europe",
  },
  {
    displayText: "Italy",
    indexName: "Italy",
    regionIndexName: "Central Europe",
  },
  {
    displayText: "Spain",
    indexName: "Spain",
    regionIndexName: "Central Europe",
  },
  {
    displayText: "China",
    indexName: "China",
    regionIndexName: "Asia",
  },
  {
    displayText: "Hong Kong",
    indexName: "Hong Kong",
    regionIndexName: "Asia",
  },
  {
    displayText: "Singapore",
    indexName: "Singapore",
    regionIndexName: "Asia",
  },
  {
    displayText: "Taiwan",
    indexName: "Taiwan",
    regionIndexName: "Asia",
  },
  {
    displayText: "Korea",
    indexName: "Korea",
    regionIndexName: "Asia",
  },
  {
    displayText: "Thailand",
    indexName: "Thailand",
    regionIndexName: "Asia",
  },
  {
    displayText: "Malaysia",
    indexName: "Malaysia",
    regionIndexName: "Asia",
  },
  {
    displayText: "Philippines",
    indexName: "Philippines",
    regionIndexName: "Asia",
  },
];