import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CommonLayout from "./layouts/commonlayout";
import { PolicyHome, PolicyChat, ContractHome, ContractChat } from "./pages";

import ChatContextProvider from "./contexts/ChatContext/ChatContextProvider";
import { Authenticator } from "./components";
import Citation from "./pages/citation/citation";

const router = createBrowserRouter([
  {
    path: "/",
    Component: CommonLayout,
    children: [
      {
        path: "/",
        Component: PolicyHome,
      },
      {
        path: "/policy",
        Component: PolicyHome,
      },
      {
        path: "/policy/chat",
        Component: PolicyChat,
      },
      {
        path: "/contract",
        Component: ContractHome,
      },
      {
        path: "/contract/chat",
        Component: ContractChat,
      },
      {
        path: "policy/citation",
        Component: Citation,
      },
      {
        path: "contract/citation",
        Component: Citation,
      },
    ],
  },
]);

const App = () => {
  return (
    <ChatContextProvider>
      <Authenticator>
        <RouterProvider router={router} />
      </Authenticator>
    </ChatContextProvider>
  );
};

export default App;
