import React, { RefAttributes, useEffect, useRef, useState } from "react";
import {
  WSPRule,
  ChatQuestionCard,
  ChatQuestionDock,
  StopRespondingButton,
} from "../../../components";
import useGetAIChatResponse from "../../../hooks/getAIChatResponse";
import { AIChatResponse } from "../../../models/aichatresponse";
import { ChatContextType } from "../../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../../contexts/ChatContext/ChatContextProvider";
import { useNavigate } from "react-router-dom";
import loadingimage from "../../../assets/images/Loading.gif";
import { v4 as uuidv4 } from "uuid";
import CSS from "csstype";

import DocumentViewer from "../../../components/documentviewer/documentviewer";
import useGetImageSearchResponse from "../../../hooks/getImageSearchResponse";
import { FilterScope } from "../../../enums/FilterScope";
import { base, indexes_global } from "../../../data";
import { AIChatResponseHistory } from "../../../models/aichatresponsehistory";
import { FeedbackType } from "../../../enums/FeedbackTypes";
import ErrorCard from "../../../components/errorcard/errorcard";
import submitChatFeedback from "../../../services/submitChatFeedback";
import { PinnedChat } from "../../../models/pinnedchat";
import submitChatHistory from "../../../services/submitChatHistory";
import { DomainTypes } from "../../../enums/DomainTypes";
import { indexes_specify_country } from "../../../data";
import { FilterType } from "../../../enums/FilterTypes";
import { AnswerPrefix } from "../../../enums/AnswerPrefix";
const allIndexes = base.map((item) => item.indexName);

const chatSuggestions: string[] = [
  "Tell me more.",
  "Give me a shorter summary.",
  "What are things to watch out for in a similar project?",
];

const PolicyChat = () => {
  const navigate = useNavigate();
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [questionId, setQuestionId] = useState<string>("");
  const filterNames = [...indexes_specify_country, indexes_global];
  useEffect(() => {
    chatContext?.setCurrentDomain(DomainTypes.policy);
  }, []); // Empty array ensures it runs only once

  const determineCountryIndex = () => {
    const currentIndexes = chatContext?.indexes;

    if (currentIndexes) {
      return indexes_specify_country.filter(
        (item) =>
          item.indexName ===
          currentIndexes.filter((index) => index === item.indexName)[0]
      ).length > 0
        ? [
            indexes_specify_country.filter(
              (item) =>
                item.indexName ===
                currentIndexes.filter((index) => index === item.indexName)[0]
            )[0].indexName,
          ]
        : [];
    } else {
      return [];
    }
  };

  const determineRegionalIndex = () => {
    const currentIndexes = chatContext?.indexes;

    if (currentIndexes) {
      return indexes_specify_country.filter(
        (item) =>
          item.indexName ===
          currentIndexes.filter(
            (index) =>
              index === item.indexName && index !== item.regionIndexName
          )[0]
      ).length > 0
        ? [
            indexes_specify_country.filter(
              (item) =>
                item.indexName ===
                currentIndexes.filter(
                  (index) =>
                    index === item.indexName && index !== item.regionIndexName
                )[0]
            )[0].regionIndexName ?? "",
          ]
        : [];
    } else {
      return [];
    }
  };

  // chat response - global
  const {
    data,
    loading,
    abortControllerRef,
    streaming,
    streamResponse,
    error,
  } = useGetAIChatResponse(
    chatContext ? chatContext?.chat.question : "",
    ["Global"],
    chatContext ? chatContext?.sessionId : uuidv4().replaceAll("-", ""),
    questionId
  );

  // chat response - regional
  const {
    data: regionData,
    loading: regionLoading,
    abortControllerRef: regionAbortControllerRef,
    streaming: regionStreaming,
    streamResponse: regionStreamResponse,
    error: regionError,
  } = useGetAIChatResponse(
    chatContext ? chatContext?.chat.question : "",
    determineRegionalIndex(),
    chatContext ? chatContext?.sessionId : uuidv4().replaceAll("-", ""),
    questionId
  );

  // chat response - country
  const {
    data: countryData,
    loading: countryLoading,
    abortControllerRef: countryAbortControllerRef,
    streaming: countryStreaming,
    streamResponse: countryStreamResponse,
    error: countryError,
  } = useGetAIChatResponse(
    chatContext ? chatContext?.chat.question : "",
    determineCountryIndex(),
    chatContext ? chatContext?.sessionId : uuidv4().replaceAll("-", ""),
    questionId
  );

  const latestAnswerRef = useRef<null | HTMLDivElement>(null);
  const [documentToShow, setDocumentToShow] = useState<string>("");

  const [dataToDisplay, setDataToDisplay] = useState<AIChatResponseHistory[]>(
    []
  );
  const [groupedDataToDisplay, setGroupedDataToDisplay] = useState<
    [AIChatResponseHistory[]]
  >([[]]);

  // generate a unique question id
  useEffect(() => {
    setQuestionId(uuidv4().replaceAll("-", ""));
  }, []);

  // save data to history
  useEffect(() => {
    if (data) {
      let newHistoryItem: AIChatResponseHistory = {
        questionId: questionId + "_" + FilterType.Global,
        sessionId: chatContext?.sessionId,
        question: data.question,
        output: data.output,
        indexes: chatContext ? chatContext?.indexes : [],
        pin: false,
        feedbackType: FeedbackType.None,
        answerOrder: 2,
      } as AIChatResponseHistory;
      // console.log("Adding new global history item");
      // console.log(newHistoryItem);
      chatContext?.addToChatHistory(newHistoryItem);
    }
  }, [data]);

  useEffect(() => {
    if (regionData) {
      let newHistoryItem: AIChatResponseHistory = {
        questionId: questionId + "_" + FilterType.Regional,
        sessionId: chatContext?.sessionId,
        question: regionData.question,
        output: regionData.output,
        indexes: chatContext ? chatContext?.indexes : [],
        pin: false,
        feedbackType: FeedbackType.None,
        answerOrder: 1,
      } as AIChatResponseHistory;
      // console.log("Adding new regional history item");
      // console.log(newHistoryItem);
      chatContext?.addToChatHistory(newHistoryItem);
    }
  }, [regionData]);

  useEffect(() => {
    if (countryData) {
      let newHistoryItem: AIChatResponseHistory = {
        questionId: questionId + "_" + FilterType.Country,
        sessionId: chatContext?.sessionId,
        question: countryData.question,
        output: countryData.output,
        indexes: chatContext ? chatContext?.indexes : [],
        pin: false,
        feedbackType: FeedbackType.None,
        answerOrder: 0,
      } as AIChatResponseHistory;
      // console.log("Adding new country history item");
      // console.log(newHistoryItem);
      chatContext?.addToChatHistory(newHistoryItem);
    }
  }, [countryData]);

  useEffect(() => {
    if (chatContext && chatContext.chatHistory) {
      // console.log("Chat History");
      // console.log(chatContext.chatHistory);

      setDataToDisplay(chatContext.chatHistory);

      const groupedDataToDisplay = chatContext.chatHistory.reduce(
        (result: { [key: string]: AIChatResponseHistory[] }, obj) => {
          const questionId = obj.questionId.split("_")[0];
          if (!result[questionId]) {
            result[questionId] = [];
          }
          result[questionId].push(obj);
          return result;
        },
        {} as { [key: string]: AIChatResponseHistory[] }
      );
      setGroupedDataToDisplay(
        Object.values(groupedDataToDisplay) as [AIChatResponseHistory[]]
      );
    }
  }, [chatContext?.chatHistory]);

  const handleFollowUpQuestion = (value: string) => {
    chatContext?.saveChat({ question: value, history: "" });
  };

  useEffect(() => {
    if (dataToDisplay && dataToDisplay.length > 0) {
      if (latestAnswerRef.current !== null) {
        latestAnswerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [loading, dataToDisplay, chatContext?.activeQuestionId]);

  const handleCitationChange = (value: string) => {
    if (value.length > 0) {
      if (value === documentToShow) {
        setDocumentToShow("");
      } else {
        setDocumentToShow("");
        setDocumentToShow(value);
      }
    }
  };

  const handlePinConversation = (index: number, questionId: string) => {
    // console.log("Pin conversation");
    // console.log("index: ", index);
    // console.log(chatContext?.chatHistory);

    if (chatContext && chatContext.chatHistory) {
      const itemsToUpdate = chatContext.chatHistory.filter(
        (item) => item.questionId === questionId
      );
      itemsToUpdate[0].pin = !itemsToUpdate[0].pin;
      // chatContext.chatHistory[index].pin = !chatContext.chatHistory[index].pin;

      const sesstionId = chatContext.chatHistory[0].sessionId;

      // before add or remove a pinned conversation and then save to db
      // we should check if the id of pinned conversation exists in the chat history
      const questionIdPrefix = questionId.split("_")[0];
      if (chatContext?.pinnedChatSessionsData) {
        Object.entries(chatContext?.pinnedChatSessionsData).forEach((item) => {
          return item.forEach((subItem) => {
            if (typeof subItem === "object") {
              return subItem.forEach((pinnedChatItem) => {
                if (
                  pinnedChatItem.sessionId === sesstionId &&
                  pinnedChatItem.questionId.includes(questionIdPrefix)
                ) {
                  // if the id of pinned conversation exists in the chat history,
                  // we should set it for the pinned conversation
                  // so that the backend will not create a new record in db, just update the exsiting record
                  const id = pinnedChatItem.id ?? "";
                  const pinnedChatItemQuestionId =
                    pinnedChatItem.questionId ?? "";
                  chatContext.chatHistory.forEach((chatItem) => {
                    if (
                      !chatItem.id &&
                      chatItem.sessionId === sesstionId &&
                      chatItem.questionId === pinnedChatItemQuestionId
                    ) {
                      chatItem.id = id;
                    }
                  });
                }
              });
            }
          });
        });
      }

      chatContext?.addPinnedConversation(chatContext.chatHistory, index);
    }
  };

  //TODO: move it to shared css styling file once the position of button gets confirmed
  const stopBtnStyle: CSS.Properties = {
    backgroundColor: "#fff",
    position: "fixed",
    bottom: "2%",
    left: "50%",
  };

  const saveFeedbackToDB = () => {
    const submitChatFeedbackResponse = submitChatFeedback(
      dataToDisplay,
      DomainTypes.policy
    );

    if (dataToDisplay.filter((item) => item.pin === true).length > 0) {
      const submitChatHistoryResponse = submitChatHistory(
        {
          initialMessage: dataToDisplay[0].question,
          sessionId: dataToDisplay[0].sessionId,
          chatMessages: dataToDisplay,
        } as PinnedChat,
        DomainTypes.policy
      );
    }
  };

  const getResponseHeading = (indexes: string[], filterType: string) => {
    if (filterType === FilterType.Country) {
      const countryIndexes = indexes.filter((item) => item !== "All");

      if (
        countryIndexes &&
        countryIndexes.length > 0 &&
        countryIndexes.length < indexes_specify_country.length
      ) {
        const AnswerCountrySuffix = indexes_specify_country.filter(
          (element) => {
            return countryIndexes.find((index) => index === element.indexName);
          }
        )[0].displayText;

        return AnswerPrefix.Country + " " + AnswerCountrySuffix;
      }
    }
    if (filterType === FilterType.Regional) {
      if (indexes && indexes.length < indexes_specify_country.length) {
        const AnswerRegionalSuffix = indexes_specify_country.filter((index) => {
          return indexes.find((element) => element === index.indexName);
        })[0].regionIndexName;
        return AnswerPrefix.Regional + " " + AnswerRegionalSuffix;
      }
    }
    return AnswerPrefix.Global;
  };

  return loading || regionLoading || countryLoading ? (
    <div className="h-full flex">
      <div className="flex flex-col m-auto w-4/5 pt-6 text-center">
        <div>
          <h4 className="text-red font-semibold">
            {chatContext?.chat.question}
          </h4>
        </div>
        <div className="m-auto">
          <img src={loadingimage} />
        </div>
        <div className="mb-6">Loading</div>
        <div>
          <StopRespondingButton
            abortControllerRef={[abortControllerRef]}
          ></StopRespondingButton>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="flex flex-col w-full min-w-0">
        {chatContext &&
          chatContext?.chat.question.length > 0 &&
          !loading &&
          !regionLoading &&
          !countryLoading &&
          !streaming && (
            <ChatQuestionDock
              FollowUpQuestions={chatSuggestions}
              callback={handleFollowUpQuestion}
              abortControllerRef={[abortControllerRef]}
              setQuestionId={setQuestionId}
            />
          )}
        <div className="mx-auto w-10/12 text-center pt-4 pb-2 border-b border-[#F2F2F2]">
          <div className="truncate">
            Home /{" "}
            {dataToDisplay.length > 0
              ? dataToDisplay[0].question
              : chatContext?.chat.question}
          </div>
          <div className="truncate text-red font-semibold">
            This tool provides WSP Global and Regional policy information.
          </div>
        </div>
        <div className="mx-auto w-10/12 flex flex-row gap-4 min-w-0">
          <div className={documentToShow ? "w-1/2 flex" : "flex m-auto"}>
            <div className="flex flex-col pt-6 overflow-hidden m-auto">
              <div>
                <div className="grid grid-cols-1 gap-4">
                  {((!loading &&
                    !streaming &&
                    !regionLoading &&
                    !countryLoading &&
                    dataToDisplay.length < 1 &&
                    !abortControllerRef.current.signal.aborted) ||
                    error) && (
                    <ErrorCard title="Something went wrong" error={error} />
                  )}

                  {!loading &&
                    !streaming &&
                    !regionStreaming &&
                    !countryStreaming &&
                    dataToDisplay.length < 1 &&
                    abortControllerRef.current.signal.aborted && (
                      <ErrorCard
                        title="Response stopped"
                        error="As requested, your response has been stopped."
                      />
                    )}

                  {groupedDataToDisplay.map((dataToDisplayItem, i) => (
                    <>
                      {dataToDisplayItem
                        .sort((a, b) => {
                          if (a.answerOrder! < b.answerOrder!) {
                            return -1;
                          }
                          if (a.answerOrder! > b.answerOrder!) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((item, i) => (
                          <div
                            key={item.question + i}
                            ref={
                              item.questionId === chatContext?.activeQuestionId
                                ? latestAnswerRef
                                : null
                            }
                          >
                            <ChatQuestionCard
                              chatMessage={item}
                              displayQuestion={i === 0}
                              responseHeading={getResponseHeading(
                                item.indexes,
                                item.questionId.split("_")[1]
                              )}
                              streamResponse={null}
                              streaming={streaming}
                              filterNames={filterNames}
                              callback={handleCitationChange}
                              pinnedConversationCallback={handlePinConversation}
                              saveFeedbackCallback={saveFeedbackToDB}
                              index={i}
                            />
                          </div>
                        ))}
                      <div className="pb-2">
                        <WSPRule />
                      </div>
                    </>
                  ))}
                  {streamResponse && streaming && (
                    <>
                      <ChatQuestionCard
                        chatMessage={
                          {
                            question: chatContext
                              ? chatContext?.chat.question
                              : "",
                            output: "",
                            indexes: chatContext ? chatContext?.indexes : [],
                            pin: false,
                          } as AIChatResponseHistory
                        }
                        displayQuestion={true}
                        responseHeading={getResponseHeading(
                          chatContext!.indexes,
                          "global"
                        )}
                        streamResponse={streamResponse}
                        streaming={streaming}
                        filterNames={filterNames}
                        callback={handleCitationChange}
                        pinnedConversationCallback={handlePinConversation}
                        saveFeedbackCallback={saveFeedbackToDB}
                        index={0}
                      />
                    </>
                  )}

                  {regionStreamResponse && regionStreaming && (
                    <ChatQuestionCard
                      chatMessage={
                        {
                          question: chatContext
                            ? chatContext?.chat.question
                            : "",
                          output: "",
                          indexes: chatContext ? chatContext?.indexes : [],
                          pin: false,
                        } as AIChatResponseHistory
                      }
                      displayQuestion={false}
                      responseHeading={getResponseHeading(
                        chatContext!.indexes,
                        "region"
                      )}
                      streamResponse={regionStreamResponse}
                      streaming={regionStreaming}
                      filterNames={filterNames}
                      callback={handleCitationChange}
                      pinnedConversationCallback={handlePinConversation}
                      saveFeedbackCallback={saveFeedbackToDB}
                      index={0}
                    />
                  )}
                  {countryStreamResponse && countryStreaming && (
                    <ChatQuestionCard
                      chatMessage={
                        {
                          question: chatContext
                            ? chatContext?.chat.question
                            : "",
                          output: "",
                          indexes: chatContext ? chatContext?.indexes : [],
                          pin: false,
                        } as AIChatResponseHistory
                      }
                      displayQuestion={false}
                      responseHeading={getResponseHeading(
                        chatContext!.indexes,
                        "country"
                      )}
                      streamResponse={countryStreamResponse}
                      streaming={countryStreaming}
                      filterNames={filterNames}
                      callback={handleCitationChange}
                      pinnedConversationCallback={handlePinConversation}
                      saveFeedbackCallback={saveFeedbackToDB}
                      index={0}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {documentToShow && (
            <div className="flex w-1/2">
              <div className="flex flex-col bg-light-gray fixed h-[94vh] w-2/6">
                <div className="ml-auto">
                  <button
                    type="button"
                    className="p-2"
                    onClick={() => setDocumentToShow("")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8327 1.34163L10.6577 0.166626L5.99935 4.82496L1.34102 0.166626L0.166016 1.34163L4.82435 5.99996L0.166016 10.6583L1.34102 11.8333L5.99935 7.17496L10.6577 11.8333L11.8327 10.6583L7.17435 5.99996L11.8327 1.34163Z"
                        fill="#3D5F7B"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={
                    documentToShow.includes("pdf")
                      ? "border w-full h-full"
                      : "border w-full"
                  }
                >
                  <DocumentViewer blobName={documentToShow} />
                </div>
              </div>
            </div>
          )}
        </div>
        {streaming && (
          <div style={stopBtnStyle}>
            <StopRespondingButton
              abortControllerRef={[abortControllerRef]}
            ></StopRespondingButton>
          </div>
        )}
      </div>
    </>
  );
};

export default PolicyChat;
