import { useContext, useEffect, useRef, useState } from "react";
import {
  msalInstance as msalInstanceAAD,
  getAadAuthToken as getAuthToken,
} from "../components/authenticator/azureAAD/Authenticator";
import { mockResponse3 } from "../data/mockresponse3";
import { mockResponse5 } from "../data/mockresponse5";
import { ChatContextType } from "../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../contexts/ChatContext/ChatContextProvider";
import { SearchType } from "../enums/SearchTypes";
import { AIChatResponse } from "../models/aichatresponse";

interface StreamState {
  data: Uint8Array | null;
  error: Error | null;
  controller: AbortController;
}

function useGetAIChatResponse(
  message: string,
  indexes: string[],
  sessionId: string,
  questionId: string
) {
  const chatContext = useContext<ChatContextType | null>(ChatContext);
  const [data, setData] = useState<AIChatResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [streaming, setStreaming] = useState(false);
  const [streamResponse, setStreamResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [state, setState] = useState<StreamState>({
    data: null,
    error: null,
    controller: new AbortController(),
  });

  const decoder = new TextDecoder();

  const body =
    `{
      "role": "user",
      "content": ` +
    JSON.stringify(message.replace(/\r\n|\n|\r/gi, " ")) +
    `,
      "indexes": "` +
    indexes.toString() +
    `", 
      "session_id": "` +
    sessionId +
    `"}`;

  // console.log("useGetAIChatResponse");
  // console.log("indexes");
  // console.log(indexes);

  useEffect(() => {
    (async () => {
      if (message && indexes.length > 0) {
        try {
          setLoading(true);
          const authToken = await getAuthToken();
          const resp = await fetch(process.env.REACT_APP_CHAT_COMPLETION_URL!, {
            method: "POST",
            signal: abortControllerRef.current.signal,
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body,
          });
          if (!resp.ok || !resp.body) {
            throw resp.statusText;
          }

          let chunks = "";
          let thisChunk = "";
          const reader = resp.body.getReader();
          while (true) {
            setLoading(false);
            setStreaming(true);
            const { done, value } = await reader.read();

            if (done) {
              break;
            }

            thisChunk = decoder.decode(value);

            chunks += thisChunk;

            setStreamResponse(chunks);
          }

          const finalData: AIChatResponse = {
            question: message,
            output: JSON.parse(chunks.split("|")[1]).output,
            history: [],
            session_id: sessionId,
          } as AIChatResponse;

          setStreaming(false);
          setData(finalData);
        } catch (err) {
          console.log("Chat completion error");
          console.log(err);
          console.log("Abort abortControllerRef ref");
          console.log(abortControllerRef);
          if (abortControllerRef.current.signal.aborted) {
            setError("The response has been stopped.");
          }

          setStreaming(false);
          setLoading(false);
        }
      }
    })();

    return () => state.controller.abort();
  }, [body]);

  return {
    data,
    loading,
    abortControllerRef,
    streaming,
    streamResponse,
    error: state.error?.message.toString(),
  };
}

export default useGetAIChatResponse;
