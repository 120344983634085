import { IndexDefinition } from "../models/indexdefinition";

export const indexes_contract_clause: IndexDefinition[] = [
  { indexName: "All", displayText: "All Contract Clauses" },
  { indexName: "Audit Rights", displayText: "Audit Rights" },
  {
    indexName: "Authority to Obligate Client (None)",
    displayText: "Authority to Obligate Client (None)",
  },
  { indexName: "Background Checks", displayText: "Background Checks" },
  {
    indexName: "Bond/Parent Company Guarantee",
    displayText: "Bond/Parent Company Guarantee",
  },
  {
    indexName: "Certifications - Client Inquiries in re Litigation/ Claims",
    displayText: "Certifications - Client Inquiries in re Litigation/ Claims",
  },
  { indexName: "Changes in Law", displayText: "Changes in Law" },
  { indexName: "Changes in Scope", displayText: "Changes in Scope" },
  { indexName: "Choice of Law/Venue", displayText: "Choice of Law/Venue" },
  {
    indexName: "Confidentiality Limitation",
    displayText: "Confidentiality Limitation",
  },
  { indexName: "Consequential Damages", displayText: "Consequential Damages" },
  {
    indexName: "Control of Work and Job-Site Safety",
    displayText: "Control of Work and Job-Site Safety",
  },
  {
    indexName: "Data Hosting and Application Development",
    displayText: "Data Hosting and Application Development",
  },
  {
    indexName: "Entire Agreement/ Precedence",
    displayText: "Entire Agreement/ Precedence",
  },
  {
    indexName: "Fees and Payment Terms",
    displayText: "Fees and Payment Terms",
  },
  { indexName: "Fiduciary Duties", displayText: "Fiduciary Duties" },
  { indexName: "Force Majeure", displayText: "Force Majeure" },
  {
    indexName: "Governing Law & Dispute Resolution",
    displayText: "Governing Law & Dispute Resolution",
  },
  { indexName: "Government Client", displayText: "Government Client" },
  { indexName: "Indemnification", displayText: "Indemnification" },
  { indexName: "Insurance", displayText: "Insurance" },
  { indexName: "Intellectual Property", displayText: "Intellectual Property" },
  { indexName: "Jury Trial Waiver", displayText: "Jury Trial Waiver" },
  { indexName: "Licenses & Permits", displayText: "Licenses & Permits" },
  {
    indexName: "Limitation of Liability",
    displayText: "Limitation of Liability",
  },
  {
    indexName: "Limitation of Recourse",
    displayText: "Limitation of Recourse",
  },
  { indexName: "Liquidated Damages", displayText: "Liquidated Damages" },
  { indexName: "Non-Solicitation", displayText: "Non-Solicitation" },
  {
    indexName: "Novation and Assignment",
    displayText: "Novation and Assignment",
  },
  { indexName: "Onerous Terms", displayText: "Onerous Terms" },
  { indexName: "Privacy and Security", displayText: "Privacy and Security" },
  {
    indexName: "Reliance on Client-Supplied Information",
    displayText: "Reliance on Client-Supplied Information",
  },
  { indexName: "Scope of Services", displayText: "Scope of Services" },
  { indexName: "Standard of Care", displayText: "Standard of Care" },
  { indexName: "Subcontracting", displayText: "Subcontracting" },
  { indexName: "Termination", displayText: "Termination" },
  {
    indexName: "Time is of the Essence",
    displayText: "Time is of the Essence",
  },
  { indexName: "Warranty", displayText: "Warranty" },
];
