import { PromptSnippet } from "../models/promptsnippet";

export const promptSnippets: PromptSnippet[] = [
  {
    text: "What is WSP's policy with regard to...",
    value: "What is WSP's policy with regard to ",
  },
  {
    text: "What guidance is available for the following situation...",
    value: "What guidance is available for the following situation ",
  },
  {
    text: "What is the process for...",
    value: "What is the process for ",
  },
];

export const promptGroup1: PromptSnippet[] = [
  {
    text: "How should I report a conflict of interest?",
    value: "How should I report a conflict of interest?",
  },
  {
    text: "What are the guidelines on charitable considerations for less than $1000? What should I be looking out for?",
    value: "What are the guidelines on charitable considerations for less than $1000? What should I be looking out for?",
  },
  {
    text: "What is the difference between a strainburst and a shear rupture?",
    value: "What is the difference between a strainburst and a shear rupture?",
  },
  {
    text: "How does rockburst damage impact production from cave mines?",
    value: "How does rockburst damage impact production from cave mines?",
  },
  {
    text: "What is the difference between strategic and tactical controls for rockbursting?",
    value: "What is the difference between strategic and tactical controls for rockbursting?",
  },
  {
    text: "How can brittle rock mass behaviour be captured in continuum models?",
    value: "How can brittle rock mass behaviour be captured in continuum models?",
  },
];

export const promptGroup2: PromptSnippet[] = [
  {
    text: "How to log veined rock core?",
    value: "How to log veined rock core?",
  },
  {
    text: "How to estimate corrosion rates for steel ground support in mines?",
    value: "How to estimate corrosion rates for steel ground support in mines?",
  },
  {
    text: "How to calculate the maximum stress at the boundary of a tunnel?",
    value: "How to calculate the maximum stress at the boundary of a tunnel?",
  },
];

export const promptGroup3: PromptSnippet[] = [
  {
    text: "What are key challenges in the DMLZ at Grasberg?",
    value: "What are key challenges in the DMLZ at Grasberg?",
  },
  {
    text: "What are the common vein types encountered at Red Chris and their implications for caving?",
    value: "What are the common vein types encountered at Red Chris and their implications for caving?",
  },
  {
    text: "What are possible challenges for caving with the silicic cap domain at the Hu'u project?",
    value: "What are possible challenges for caving with the silicic cap domain at the Hu'u project?",
  },
];

export const promptGroupPolicy: PromptSnippet[] = [
  {
    text: "Who do I talk to if I think someone is doing something unethical?",
    value: "Who do I talk to if I think someone is doing something unethical?",
  },
  {
    text: "What is the definition of personal information and how must it be handled?",
    value: "What is the definition of personal information and how must it be handled?",
  },
  {
    text: 'What is the difference between a Health and Safety "observation", "near miss", or "incident" and how do I record it?',
    value: 'What is the difference between a Health and Safety "observation", "near miss", or "incident" and how do I record it?',
  }
];

export const ContractGuidelineQuestions: PromptSnippet[] = [
  {
    text: "Onerous Terms",
    value: "What are onerous terms, and why is it important to identify and negotiate them in contracts?",
  },
  {
    text: "Scope of Services",
    value: "How should the scope of services be clearly defined to prevent misunderstandings and disputes with the other party and ensure proper compensation?",
  },
  {
    text: "Insurance",
    value: "What types of insurance should be considered, and why is it important to verify compliance with insurance requirements in contracts?",
  },
  {
    text: "Standard of Care",
    value: "What is the standard of care in professional services, and why is it crucial to avoid contract language that imposes a higher standard?",
  },
  {
    text: "Warranty",
    value: "How do warranties differ from standard of care provisions, and what language should be included to limit warranty obligations? Why is it important to avoid fitness for purpose provisions and what are their impact?",
  },
  {
    text: "Time is of the Essence",
    value: 'What does the phrase "time is of the essence" mean in a contract, and how can it affect a project\'s timeline and obligations?',
  },
  {
    text: "Termination",
    value: "What are the key considerations for negotiating termination clauses, and how should termination for convenience be addressed?",
  },
  {
    text: "Fees and Payment Terms",
    value: "What should be included in a contract to ensure clear understanding of fees, payment timelines, and the handling of late payments?",
  },
  {
    text: "Intellectual Property",
    value: "How should intellectual property rights be addressed in a contract, and what should be considered when licensing IP to clients?",
  },
  {
    text: "Indemnification",
    value: "Why is it important to limit indemnification clauses in favor of other parties, and how should indemnity language be crafted to reflect proportional responsibility?",
  },
  {
    text: "Limitation of Liability",
    value: 'What is the purpose of a limitation of liability clause, and what exclusions or "carve outs" are typically acceptable?',
  },
  {
    text: "Consequential Damages",
    value: "Why should contracts include a waiver of consequential and indirect damages, and what types of damages are typically considered consequential?",
  },
  {
    text: "Reliance on Client-Supplied Information",
    value: "How should reliance on client-supplied information be addressed in a contract to protect against inaccuracies and additional incurred costs?",
  },
  {
    text: "Governing Law & Dispute Resolution",
    value: "Why is it important to specify governing law and dispute resolution processes in a contract, and what are WSP’s preferences?",
  },
  {
    text: "Data Hosting and Application Development",
    value: "What considerations should be made when contracts involve data hosting or application development services?",
  },
  {
    text: "Privacy and Security",
    value: "How should contracts address data privacy and security, especially when handling third-party data?",
  },
  {
    text: "Novation and Assignment",
    value: "What are the implications of novation and assignment clauses, and why should they require express written consent?",
  },
  {
    text: "Audit Rights",
    value: "What are audit rights, and what should be considered when defining the scope and limitations of these rights in a contract?",
  },
  {
    text: "Authority to Obligate Client (None)",
    value: "Why is it important to clarify that the consultant has no authority to obligate the client, and how should this be stated?",
  },
  {
    text: "Background Checks",
    value: "What role do background checks play in contracts, and what responsibilities should be outlined regarding them?",
  },
  {
    text: "Bond/Parent Company Guarantee",
    value: "What are bonds and parent company guarantees, and why should their inclusion in contracts trigger a review process?",
  },
  {
    text: "Certifications - Client Inquiries in re Litigation/ Claims",
    value: "How should inquiries regarding litigation or claims be addressed in contracts to protect the company?",
  },
  {
    text: "Changes in Law",
    value: "How should contracts address changes in law that affect the performance of services, the project schedule or the project\'s cost?",
  },
  {
    text: "Changes in Scope",
    value: "What are the best practices for managing changes in scope, and how should they be documented in a contract?",
  },
  {
    text: "Choice of Law/Venue",
    value: "Why is specifying choice of law and venue important in contracts, and what considerations should guide these choices?",
  },
  {
    text: "Confidentiality Limitation",
    value: "How should confidentiality limitations be structured in a contract to balance legal obligations and business needs?",
  },
  {
    text: "Control of Work and Job-Site Safety",
    value: "What responsibilities should be clearly defined regarding control of work and job-site safety in a contract?",
  },
  {
    text: "Entire Agreement/ Precedence",
    value: "Why is it important to include an entire agreement clause, and how should precedence be established among contract documents?",
  },
  {
    text: "Fiduciary Duties",
    value: "What are fiduciary duties, and why should a contract explicitly state that the relationship is contractual rather than fiduciary?",
  },
  {
    text: "Force Majeure",
    value: "How does a force majeure clause protect parties in a contract, and what events are typically included in such a clause?",
  },
  {
    text: "Government Client",
    value: "What specific considerations should be taken into account when contracting with a government client?",
  },
  {
    text: "Jury Trial Waiver",
    value: "Why might parties choose to waive their right to a jury trial, and what should be included in the waiver clause?",
  },
  {
    text: "Licenses & Permits",
    value: "What should be clarified in a contract regarding the responsibility for obtaining licenses and permits?",
  },
  {
    text: "Limitation of Recourse",
    value: "How can limitation of recourse clauses protect a company, and what should they typically include?",
  },
  {
    text: "Liquidated Damages",
    value: "What are liquidated damages, and what should be considered when including such a clause in a contract?",
  },
  {
    text: "Non-Solicitation",
    value: "What is the purpose of a non-solicitation clause, and when might it be particularly relevant?",
  },
  {
    text: "Subcontracting",
    value: "What key points should be addressed in a contract regarding subcontracting, especially concerning flow-down requirements?",
  }
];

export const ContractCannedQuestions: PromptSnippet[] = [
  {
    text: "What are “onerous” terms?",
    value: "What are “onerous” terms?",
  },
  {
    text: 'What does the "standard of care" mean in the context of a professional services contract and how should I review that clause?',
    value: 'What does the "standard of care" mean in the context of a professional services contract and how should I review that clause?',
  },
  {
    text: 'Can we list a client as an additional insured?',
    value: 'Can we list a client as an additional insured?',
  },
  {
    text: 'Why is it essential to have a clearly defined scope of services in a contract?',
    value: 'Why is it essential to have a clearly defined scope of services in a contract?',
  }
];